import React, { useContext } from "react";
import { FormContext } from "../../../App";
import SingleStepEstimate from './SingleStepEstimate';
import FinalStep from './FinalStep';
import TrustedFormScript from "components/atoms/TrustedFormScript";

const SingleStepper = (props) => {
  const { activeStepIndex } = useContext(FormContext);

  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <SingleStepEstimate {...props} />;
      break;
    case 1:
      stepContent = <FinalStep {...props} />;
      break;
    default:
      break;
  }

  return (
    <>
      <div className="leaffilter-forms single-step-estimate">{stepContent}</div>
      <TrustedFormScript />
    </>
  );
}

export default SingleStepper;
