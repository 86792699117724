
import { useProduct } from "hooks";
import React, { useContext } from "react";
import { FormContextLHSSMultiStepEstimate } from "components/Forms/LHSS/LHSS-MultiStep-Estimate/LHSSMultiStepEstimate";
import { FormContextLHWSMultiStepEstimate } from "components/Forms/LHWS/LHWSMultiStepEstimate/LHWSMultiStepEstimate";
import { FormContext } from "App";
import LeafBox from 'components/atoms/LeafBox';

const ProgressBarWithPercentage = (props) => {

  const { activeStepIndex } = useContext(FormContext);
  const { activeLHSSStepIndex } = useContext(FormContextLHSSMultiStepEstimate);
  const { activeLHWSStepIndex } = useContext(FormContextLHWSMultiStepEstimate);

  let  progressIndex = 0;
  const product = useProduct();
  let backgroundColor = '#00853e';


  const lhssProgressSteps = [
    'group',
    'details',
    'project',
    'final'
  ];

  const progressSteps = [
   1,
   2,
   3,
   4
  ];

  switch(product) {

    case 'lhss':
      progressIndex  = lhssProgressSteps.indexOf(activeLHSSStepIndex);
      backgroundColor = '#3d7491';
      break;
    case 'lhws':
      progressIndex  = lhssProgressSteps.indexOf(activeLHWSStepIndex);
      backgroundColor = '#00853e';
      break;
    default:
      progressIndex = progressSteps.indexOf(activeStepIndex);
      break;

  }

  const progressTextstyles = {
    color: '#767676',
    fontSize: '12.992px',
    fontWeight: '400',
    margin: '0 0 4.8px',
  };

  return (
    <LeafBox className="mb-0 mx-auto">
      <LeafBox className="w-100 text-center mb-2" style={progressTextstyles}>{`Step ${progressIndex + 2} of 5`}</LeafBox>
      <LeafBox className="progress-bar w-full mb-4"
        style={{ backgroundColor: '#d2d6dc', borderRadius: '10.5px', height: '20px'}}
      >
        <LeafBox className="text-end mb-0"
          style={{ width: `${100 - (20 * (5 - (progressIndex + 2)))}%`, backgroundColor: backgroundColor, borderRadius: '10.5px'}}
          role="progressbar"
        >
          <span className="mx-2 text-white">{`${100 - (20 * (5 - (progressIndex + 2)))}%`}</span>
        </LeafBox>
      </LeafBox>
    </LeafBox>
  );
}

export default ProgressBarWithPercentage;
