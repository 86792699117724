import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContextReferral } from "../Referral";
import { FormContext } from "../../../../App";
import { Container } from "@mui/material";
import "./style.scss";
import Image from './referral/1200x1200refer-a-friend.jpg';

const InitialStep = () => {
  const { setActiveStepIndex } =
    useContext(FormContext);
  const { setReferral } =
    useContext(FormContextReferral);
  const ReferFriendImage = Image;

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => {
        setReferral('ReferAFriend');
        setActiveStepIndex('verify');
      }}
    >
      {({ handleSubmit}) => (
        <Container disableGutters={true}>
          <Form
            className="flex flex-col justify-center items-start step1"
            onSubmit={handleSubmit}
          >
            <div className="refer-friend">
              <img className="profile-photo" src={ReferFriendImage} alt={"Two women in conversation with eachother talking about leaf filter gutter protection"}/>
              <div className="choice__bottom">
                <button className="choice__button" type="submit">
                  Refer a Friend
                </button>
              </div>
            </div>
          </Form>
        </Container>
      )}
    </Formik>
  );
}

export default InitialStep;
