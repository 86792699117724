import { Box } from "@mui/material";
import { ReactComponent as FacebookIcon } from "components/Forms/form-icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "components/Forms/form-icons/twitter.svg";
import { ReactComponent as BlogIcon } from "components/Forms/form-icons/blog.svg";
import { ReactComponent as InstagramIcon } from "components/Forms/form-icons/instagram.svg";
import { useBlogURL, useFacebookURL, useInstaURL, useTwitterURL } from "utility/countryOverride";

/**
 * Get Facebook Social Media Icon.
 *
 * @returns
 * Icon component for Facebook
 */
export const FacebookSocialIcon = () => {
    const facebookUrl = useFacebookURL();

    return (
        <a className="facebook" href={facebookUrl}>
              <Box sx={{
                borderRadius: '50%',
                border: "2px solid #e8e8e8",
                width: '60px',
                height: '60px',
                padding: '10px',
                margin: 'auto',
              }}>
                <FacebookIcon />
              </Box>
              <div className="text-center m-2"> Facebook </div>
        </a>
    )
}

/**
 * Get Twitter Social Media Icon
 *
 * @returns
 *  Icon component for Twitter
 */
export const TwiiterSocialIcon = () => {
    const twitterURL = useTwitterURL();
    return (
        <a className="twitter" href={twitterURL}>
        <Box sx={{
          borderRadius: '50%',
          border: "2px solid #e8e8e8",
          width: '60px',
          height: '60px',
          padding: '10px',
          margin: 'auto',
        }}>
          <TwitterIcon />
        </Box>
        <div className="text-center m-2"> Twitter </div>
      </a>

    )
}

/**
 * Get Blog Icon.
 *
 * @returns
 *  Icon component for Blog
 */
export const BlogSocialIcon = () => {
     const blogURL = useBlogURL();
    return (
        <a className="blog" href={blogURL}>
        <Box sx={{
          borderRadius: '50%',
          border: "2px solid #e8e8e8",
          width: '60px',
          height: '60px',
          padding: '10px',
          margin: 'auto',
        }}>
          <BlogIcon />
        </Box>
        <div className="text-center m-2"> Blog </div>
      </a>
    )
}

/**
 * Get Instagram Icon
 *
 * @returns
 * Icon component for Instagram
 */
export const InstagramSocialIcon = () => {
    const instaURL = useInstaURL();
    return (
        <a className="blog" href={instaURL}>
        <Box sx={{
          borderRadius: '50%',
          border: "2px solid #e8e8e8",
          width: '60px',
          height: '60px',
          padding: '10px',
          margin: 'auto',
        }}>
          <InstagramIcon />
        </Box>
        <div className="text-center m-2"> Instagram </div>
      </a>
    )
}

