import React from "react";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import AlertMessage from "components/atoms/AlertMessage";

const LHSSThankYou = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className="service-support-form">
      <Container maxWidth={isMobile ? "xs" : "sm"}>
        <AlertMessage message={'Thanks for contacting us! We will get in touch with you shortly.'} severity={'success'} />
        <div className="thankyou-wrap">
          <p>
            Want to get in touch via email? Send us a message at
            <a href="mailto:support@leafhomesafetysolutions.com">support@leafhomesafetysolutions.com</a>
            with your phone number, zip code, and a description of your experience.
          </p>
        </div>
      </Container>
    </div>
  );
}

export default LHSSThankYou;
