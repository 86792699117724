import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { FormContext } from "../../../../App";
import * as yup from "yup";
import {
  Container,
  Paper
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import PostService from "utility/postService";
import DataLayerService from "utility/dataLayerService";
import SMSMarketingCampaignComponent from "components/atoms/SMSMarketingCampaign";
import SMSMarketingTermAndCondition from "components/atoms/SMSMarketingTermAndCondition";
import TermAndCondition from "components/atoms/TermAndCondition";
import PhoneNumberInput from "components/atoms/PhoneNumberInput";
import Validation from "utility/validation";
import LeafBox from "components/atoms/LeafBox";
import { getactiveTestScriptAP } from '../../../../utility/functions';

// Import SVG Icons.
import { LHOnline, LHTV, LHRadio, LHEvent, LHPrint, LHPostcard, LHNascar, LHYoutube, LHCtv, LHPodcast } from "components/atoms/Icons";
import { LeafButton } from "components/atoms/LeafButtons";
import { useProduct, useReCaptchaScore } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";
import ReCAPTCHA from "react-google-recaptcha";
import ShortLeadProgressBar from "../ShortLead-Form/ProgressBar";

const Step4 = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const country = useProduct();
  const activeProspectTestScript = getactiveTestScriptAP();
  let errorMsg = "";
  let captchaScore = null;

  // Disabling recaptcha.
  const reCaptchaShow = process.env.PANTHEON_ENVIRONMENT === 'live' ? (process.env.REACT_APP_RECAPTCHA_SHOW_PROD === 'enable') : (process.env.REACT_APP_RECAPTCHA_SHOW_NON_PROD === 'enable');
  const { activeStepIndex, setActiveStepIndex, formData, marketoData } =
    useContext(FormContext);

  let phoneNumber = '';
  let phoneNumberText = '';

  if (country === 'ca') {
    phoneNumberText = `I consent to receive marketing text messages or phone calls
    from Leaf Home or its affiliates at the phone number provided,
    including messages or calls from autodialer.Msg & data rates
    may apply. Msg frequency varies.Unsubscribe to text messages
    at any time by replying STOP or clicking the unsubscribe link
    (where available).`
  }

  const ValidationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Phone Number is not valid.")
      .required("Phone Number is required."),
  });

  const buttonSubmit = (e) => {

    // Validation Error.
    const phoneValidation = Validation.PhoneDataLayerValidation(phoneNumber);

    if (Object.keys(phoneValidation).length !== 0) {
      DataLayerService.formFailEvent(form, location, phoneValidation);
    }
  }

  const handleImageClick = (value, setFieldValue) => {
    setFieldValue("adLevel", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleOnBlur = (event) => {
    switch (event.target.name) {
      case 'phoneNumber':
        phoneNumber = event.target.value;
        break;

      default:
        break;
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const recaptchaRef = useRef();
  const reCaptchaScoreConstant = useReCaptchaScore();

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)}`,
    borderRadius: 8,
    width: "100%",
    height: "auto",
  }));

  let phone = '';
  if (marketoData !== undefined && marketoData !== null) {
    phone = marketoData.phone;
  }

  return (
    <Formik
      initialValues={{
        phoneNumber: phone ? phone : '',
        adLevel: "",
        trustedCertURL: document.getElementsByName("xxTrustedFormCertUrl")[0]
        ? document.getElementsByName("xxTrustedFormCertUrl")[0].value
        : "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={async (values, actions) => {
        // Validate if the user is not a Robot.
        let captchaValue = '';
        const RecaptchaVerification = await Validation.RecaptchaVerification();
        captchaScore = RecaptchaVerification.score;

        if (captchaScore < reCaptchaScoreConstant) {
          captchaValue = recaptchaRef.current.getValue();
        }
        else {
          captchaValue = RecaptchaVerification.token;
        }

        if (captchaValue || !reCaptchaShow) {
          const data = { ...formData, ...values };
          let comments = (props.component !== 'LeafHomeShortLeadForm' ? "Home Levels:" + formData.homeLevel + "\n" +
            "Home Size:" + formData.squareFootage + " sq ft\n" +
            "Senior:" + (formData.groupLevel === "senior" ? "Yes" : "No") + "\nVeteran:No\n" : '') +
            (country !== "ca" ? "txtSMSMarketingConsent:" + (values.txtSMSMarketingConsent ? true : false) : "txtSMSMarketingConsent:true") +
            "\nLanding Page:" + window.location.href +
            "\nTrusted Form URL:" + values.trustedCertURL +
            "\nSurvey:" + values.adLevel +
            "\nRecaptcha Score:" + captchaScore;

          let submittedValue = {
            'web_lead_source': "",
            'country': country,
            'emailAddress': formData.emailAddress,
            'firstName': formData.firstName,
            'fullName': formData.firstName + " " + formData.lastName,
            'lastName': formData.lastName,
            'phoneNumber': values.phoneNumber,
            'zipCode': formData.zipCode,
            'landing_page': window.location.pathname,
            'source': values.adLevel,
            'comments': comments,
            'recaptcha_token': captchaValue,
            'recaptcha_score': captchaScore,
            'QualifiedLead': false,
            'FirstName': formData.firstName,
            'LastName': formData.lastName,
            'Email': formData.emailAddress,
            'Phone': values.phoneNumber,
            'Zip': formData.zipCode,
            'WebEventDetail': '',
            'LeadSourceId': 1,
            'LeadSource': '41',
            'Notes': '',
          }

          try {
            // Generate Marketo Lead.
            PostService.postData('create-lead', submittedValue);
          } catch (error) {
            console.log(error);
            console.log("Error in sending data");
          }

          if (props.component === 'SelfScheduler' || props.modalComponent === 'SelfScheduler') {
            submittedValue = {
              ...submittedValue,
              ...{'utm_content': 'ss-delay'}
            }
          }

          if (activeProspectTestScript === 'nosandbox') {

            const certificateInformation = {
              'certificateLink': values.trustedCertURL,
              'userEmail': formData.emailAddress,
              'userPhone': values.phoneNumber,
            }

            try {
              const response = await PostService.retainCertificate('retain-cert', certificateInformation);
              console.log(response);
            } catch (error) {
              console.log(error);
              console.log("Error in retaining the Certificate.");
            }
          }

          try {
            const response = await PostService.postData('estimate', submittedValue, true);
            if (response.success) {
              setActiveStepIndex(activeStepIndex + 1);

              // Passing lf.form.success event to DataLayer.
              DataLayerService.formSuccessEvent(form, 'estimate', location);

              // Passing lf.form.start event to DataLayer.
              DataLayerService.formStartEvent(form, location, data);

              localStorage.removeItem("zipCode");
            }
            else {
              errorMsg = "Sorry, there was an error during submission. Please try again.";
              actions.setSubmitting(false);
            }
          } catch (error) {
            console.log(error);
            console.log("Error in sending data");
          }
        }
      }}
    >
      {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
        <Container maxWidth={"sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              className="flex flex-col justify-center items-start step-4"
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              <h6 className="tracking-wide text-uppercase mb-4 fw-normal text-gray-600 fw-normal">Free Estimate</h6>
              <h3 className="fw-normal mb-3">
                To ensure pricing is 100% accurate, we need to verify a few details.
              </h3>
              <div className="flex flex-col items-start mb-2">
                {errorMsg ? (<div className="border border-danger border-1 p-2 mb-4 error-msg fw-normal">{errorMsg}</div>) : ""}
                <PhoneNumberInput phoneNumberText={phoneNumberText} fieldName={'phoneNumber'} />
              </div>

              { country !== 'ca' ? (
                <SMSMarketingCampaignComponent />
              ) : '' }

              { (formData.utm_campaign.substr(-3, 3) !== "-ND") ? (
                <LeafBox>
                  <label className="font-medium text-green-600 small mb-3">
                    How did you hear about us?
                  </label>
                  <Field name="adLevel">
                    {() => (
                        <LeafBox>
                        <LHOnline handleClick={() =>
                          handleImageClick("41", setFieldValue)
                        } />

                        <LHTV handleClick={() =>
                          handleImageClick("81", setFieldValue)
                        } />

                        <LHCtv handleClick={() =>
                          handleImageClick("41TCTV", setFieldValue)
                        } />
                        <LHYoutube handleClick={() =>
                          handleImageClick("41PAV", setFieldValue)
                        } />

                        <LHRadio handleClick={() =>
                          handleImageClick("91", setFieldValue)
                        } />

                        <LHPrint handleClick={() =>
                          handleImageClick("88w", setFieldValue)
                        } />

                        <LHPostcard handleClick={() =>
                          handleImageClick("10w", setFieldValue)
                        } />

                        {country === 'ca' ?
                          <LHNascar handleClick={() =>
                            handleImageClick("83", setFieldValue)
                          } />
                        :
                          <LHPodcast handleClick={() =>
                            handleImageClick("85", setFieldValue)
                          } />
                        }

                        <LHEvent handleClick={() =>
                          handleImageClick("99w", setFieldValue)
                        } />
                      </LeafBox>
                    )}
                  </Field>
                </LeafBox>
              ) : '' }

              <Field type="hidden" name="trustedCertURL" />

              {reCaptchaShow && (
                <div className="recaptcha">
                  {captchaScore !== null && captchaScore < reCaptchaScoreConstant && (
                    <>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="normal"
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
                      />
                      <p className="error-msgs">reCAPTCHA verification failed. Please try again.</p>
                    </>
                  )}
                </div>
              )}
              <Divider className="divider" />
              <LeafButton
                type="submit"
                name="submit"
                buttonClass='rounded-3 btn-primary w-100 fw-semibold'
                handleClick={buttonSubmit}
                isSubmitting={isSubmitting}
                style={{
                  width: "100%"
                }}>
                {isSubmitting ? ("Submitting") : ("Submit")}
              </LeafButton>

              { (props.component === 'LeafHomeShortLeadForm' || props.modalComponent === 'LeafHomeShortLeadForm') ? (
                <ShortLeadProgressBar />
              ) : <ProgressBar /> }
              { country === 'ca' ? (
                <TermAndCondition />
              ) : <SMSMarketingTermAndCondition /> }
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default Step4;
