import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { IReferredContext } from "./IReferred";
import { FormContextReferral } from "../Referral";
import { Container } from "@mui/material";
import "./style.scss";
import Image from './referral/1200x1200referred.jpg';

const InitialStep = (props) => {
  const { setActiveIRStep } =
  useContext(IReferredContext);
  const { setReferral } =
    useContext(FormContextReferral);
  const ReferralImage = Image;

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => {
        setReferral('IReferred');
        setActiveIRStep('referral');
      }}
    >
      {({ handleSubmit}) => (
        <Container disableGutters={true}>
          <Form
            className="flex flex-col justify-center items-start step1"
            onSubmit={handleSubmit}
          >
            <div className="i-referred">
              <p className="choice__banner">Save 10%!</p>
              <img className="profile-photo" src={ReferralImage} alt={"A woman talking on the phone while writing in a notebook"}/>
              <div className="choice__bottom">
                <button className="choice__button" type="submit">
                  I Have Been Referred
                </button>
              </div>
            </div>
          </Form>
        </Container>
      )}
    </Formik>
  );
}

export default InitialStep;
