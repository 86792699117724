import { Field, useFormikContext } from "formik";
import LeafBox from 'components/atoms/LeafBox';
import TextInput from 'components/atoms/TextInput';

const CareerSource = () => {
  const { values, errors, touched } = useFormikContext();
  const inputStyle = {
    width: '100%',
    borderRadius: '3px',
    height: '40px',
    border: '1px solid #d1d1d1',
    padding: '5px 10px',
  };
  return (
    <LeafBox>
      <label htmlFor="source">
        How Did You Hear About This Opportunity?
        <span className="optional"> (optional) </span>
      </label>
      <Field
        component="select"
        name="source"
        id="source"
        style={inputStyle}
      >
        <option value="No Response" defaultValue="" disabled="">Choose</option>
        <option value="Craigslist">Craigslist</option>
        <option value="Employee Referral">Employee Referral</option>
        <option value="Facebook">Facebook</option>
        <option value="Indeed">Indeed</option>
        <option value="Newspaper">Newspaper</option>
        <option value="Radio">Radio</option>
        <option value="Search Engine">Search Engine</option>
        <option value="Television">Television</option>
        <option value="Upward">Upward</option>
        <option value="Word of Mouth">Word of Mouth</option>
        <option value="Yard Signs">Yard Signs</option>
        <option value="ZipRecruiter">ZipRecruiter</option>
        <option value="Other">Other</option>
      </Field>
      <LeafBox className={`flex flex-col items-start mb-4 mt-3 ${values.source === "Employee Referral"
        ? "d-block"
        : "d-none"
        }`}
      >
        <TextInput touched={touched} errors={errors} fieldName={'referralName'} label={"Referral Name (optional)"} placeholder={"E.g., John Smith"} />
      </LeafBox>
    </LeafBox>
  )
}

export default CareerSource;
