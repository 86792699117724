import React from "react";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useBrand } from "hooks";
import AlertMessage from "components/atoms/AlertMessage";

const ThankYouMessageCareer = (props) => {
  const brand = useBrand();
  const department = props.dataSet?.department ? props.dataSet?.department : "";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={`message-wrap ${
      brand === 'lhss' && department === 'installation' ? 'installation-message-wrap' : ""
    }`}>
      <Container maxWidth={isMobile ? "xs" : "sm"}>
        { brand === 'lhss' ? (
          <h3 className="heading">Submit your information and we will reach out to you.</h3>
          ): ""
        }
        { brand === 'lhss' && department === 'installation' ? (
          <>
            <AlertMessage message={'Thank you for your interest in joining our team of pros!'} severity={'success'} />
            <p> Curious to learn more about this opportunity? Please visit our full story here: <a target="_blank" rel="noreferrer" href="https://viewer.joomag.com/leaf-home-safety-solutions-install-recruitment-boo/0038675001642540926?short">Leaf Home Safety Solutions: Company & Career Growth</a>. We look forward to reviewing your credentials and we will be in touch to discuss next steps. </p>
          </>
          ) : <AlertMessage message={'Thank you for your submission. Someone will be in touch with you shortly.'} severity={'success'} />
        }
      </Container>
    </div>
  );
}

export default ThankYouMessageCareer;
