import { Field, useFormikContext } from 'formik';
import React from "react";
import LeafBox from 'components/atoms/LeafBox';
import ValidationIcon from "components/atoms/ValidationIcon";

const TextArea = ({fieldName, label, placeholder, required, className }) => {
  const { errors, touched } = useFormikContext();
  let inputStyle = {
    width: '100%',
    borderRadius: '3px',
    height: '40px',
    border: '1px solid #d1d1d1',
    padding: '5px 10px',
  };

  return (
    <LeafBox display="block" className={className}>
      { label ? (<label>{label}
        {required ? (<span className="required--asterisk text-danger"> * </span>) : ''}
        </label>) : ''
      }
      <Field as="textarea"
        name={fieldName}
        className={`input-field ${
          touched[fieldName] && errors[fieldName]
            ? "error"
            : touched[fieldName]
            ? "correct"
            : ""
        }`}
        placeholder={ placeholder }
        style={inputStyle}
      />
      <ValidationIcon fieldName={fieldName} touched={touched} errors={errors} />
    </LeafBox>
  );
}

export default TextArea;
