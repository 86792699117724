import { Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { FormContext } from "../../../App";
import * as yup from "yup";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import PostService from "utility/postService";
import DataLayerService from "utility/dataLayerService";
import Validation from "utility/validation";
import EmailInput from "components/atoms/EmailInput";
import PhoneNumberInput from "components/atoms/PhoneNumberInput";
import TextInput from "components/atoms/TextInput";
import CareerSource from "components/atoms/CareerSource";
import CareerLocation from "components/atoms/CareerLocation";
import { useBrand, useReCaptchaScore } from "hooks";

// @todo refactor style and try to remove it from here.
import "./style.scss";
import { LeafButton } from "components/atoms/LeafButtons";
import ReCAPTCHA from "react-google-recaptcha"

const CallCenterForm = (props) => {
  // Get the Department name.
  const department = props.dataSet?.department ? props.dataSet.department : "call-center";
  const brand = useBrand();
  let errorMsg = "";
  let captchaScore = null;
  // Disabling recaptcha.
  const reCaptchaShow = process.env.PANTHEON_ENVIRONMENT === 'live' ? (process.env.REACT_APP_RECAPTCHA_SHOW_PROD === 'enable') : (process.env.REACT_APP_RECAPTCHA_SHOW_NON_PROD === 'enable');

  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  const ValidationSchema = yup.object().shape({
    fullName: yup
      .string()
      .trim()
      .matches(/[^\s\d]+ .+$/, "Full Name must include both First Name and Last Name.")
      .required('Full Name is required.'),
    phoneNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Phone Number is not valid.")
      .required("Phone Number is required."),
    emailAddress: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email Address is not valid.')
      .required("Email Address is required."),
    location: yup.string().required('Location is required.'),
  });

  const handleOnFocus = (event) => {
    // Passing lf.form.start event to DataLayer.
    DataLayerService.formStartEvent('standard', 'career-form');

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent('standard', 'career-form');
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const recaptchaRef = useRef();
  const reCaptchaScoreConstant = useReCaptchaScore();

  return (
    <Formik
      initialValues={{
        department: '',
        emailAddress: '',
        fullName: '',
        phoneNumber: '',
        referralName: '',
        source: '',
        location: '',
      }}
      validationSchema={ValidationSchema}
      onSubmit={async (values, actions) => {
        const data = { ...formData, ...values };
        let captchaValue = '';

        const splittedValues = values.fullName.split(" ");
        const firstName = splittedValues[0];
        const lastName = splittedValues[1];

        if (reCaptchaShow) {
          // Validate if the user is not a Robot.
          const RecaptchaVerification = await Validation.RecaptchaVerification();
          captchaScore = RecaptchaVerification.score;

          if (captchaScore < reCaptchaScoreConstant) {
            captchaValue = recaptchaRef.current.getValue();
          }
          else {
            captchaValue = RecaptchaVerification.token;
          }
       }

        if (captchaValue || !reCaptchaShow) {
          let submittedValues = {
            'web_lead_source': '',
            'Web Lead Source': '',
            'country': 'US',
            'department': department,
            'emailAddress': values.emailAddress,
            'firstName' : firstName,
            'lastName': lastName,
            'fullName': values.fullName,
            'phoneNumber': values.phoneNumber,
            'referralName': values.referralName ? values.referralName : '',
            'source': values.source || "",
            'recaptcha_token' : captchaValue,
            'recaptcha_score': captchaScore,
            'location': values.location,
            'landing_page': window.location.pathname,
          }

          try {
            const response = await PostService.postData('call-center', submittedValues, true);
            if (response.success) {
              setFormData(data);
              setActiveStepIndex(activeStepIndex + 1);
            }
            else {
              errorMsg = "Sorry, there was an error during submission. Please try again.";
              actions.setSubmitting(false);
            }
          } catch (error) {
            console.log("Error in sending data");
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"}>
            <Form
              onSubmit={handleSubmit}
              onFocus={
                (event) => {handleOnFocus(event)}
              }
            >
              <h3 className="heading">Submit your information and we will reach out to you.</h3>
              {errorMsg ? (<div className="border border-danger border-1 p-2 mb-4 error-msg fw-normal">{errorMsg}</div>) : "" }
              <TextInput fieldName={'fullName'} label={'Full Name'} placeholder={"E.g., John Smith"} />
              <PhoneNumberInput fieldName={'phoneNumber'} brand={brand} />
              <EmailInput fieldName={'emailAddress'} />
              <CareerLocation></CareerLocation>
              <CareerSource></CareerSource>

              {reCaptchaShow && (
                <div className="recaptcha">
                  {captchaScore !== null && captchaScore < reCaptchaScoreConstant && (
                    <>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="normal"
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
                      />
                      <p className="error-msgs">reCAPTCHA verification failed. Please try again.</p>
                    </>
                  )}
                </div>
              )}
              <LeafButton buttonType={"tertiary"} type="submit" name="submit" isSubmitting={isSubmitting}>
                {isSubmitting ? ("Submitting") : ("Submit")}
              </LeafButton>
            </Form>
        </Container>
      )}
    </Formik>
  );
}

export default CallCenterForm;
