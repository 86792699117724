import React, { useContext } from "react";
import { FormContext } from "../../../App";
import CallCenterForm from './CallCenterForm';
import ThankYouMessageCallCenter from './ThankYouMessageCallCenter';

const CallCenter = (props) => {
  const { activeStepIndex } = useContext(FormContext);
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <CallCenterForm {...props} />;
      break;
    case 1:
      stepContent = <ThankYouMessageCallCenter {...props} />;
      break;
    default:
      break;
  }

  return <div className="leaffilter-forms career-form">{stepContent}</div>;
}

export default CallCenter;
