import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const roots = document.querySelectorAll("[data-react-component]");

roots.forEach((root) => {
  const componentId = root.dataset.reactComponent;
  const modalComponentID = root.dataset.modalComponent || null;
  const zipCheckerComponentID = root.dataset.zipcheckerComponent || null;
  const dataSet = root.dataset;
  const singleComponent = ReactDOM.createRoot(root);
  singleComponent.render(
    <App component={componentId} modalComponent={modalComponentID} zipCheckerComponent={zipCheckerComponentID} dataSet={dataSet} />
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
