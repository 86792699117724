import { Container, Box } from "@mui/material";
import { BlogSocialIcon, FacebookSocialIcon, InstagramSocialIcon, TwiiterSocialIcon } from "components/atoms/SocialMediaIcon";
import { useProduct } from "hooks";

const ThankYou = () => {
  const product = useProduct();
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        mx="auto"
        alignItems="center"
        maxWidth="500px"
      >
        <h1 className="h2 fw-normal">Thank you for Registering!</h1>
        <h2 className="h3 fw-light">Please look out for a warranty confirmation email that should be arriving soon.</h2>
        <div className="warranty__success mt-5">
          <div className="h3 fw-light">Stay Engaged with the LeafFilter Community</div>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingTop: '16px',
          }}
          >
           <FacebookSocialIcon />
          { product === 'us' || product === 'ca' ? <TwiiterSocialIcon /> : ''}
           <BlogSocialIcon />
          { product === 'lhws' ? <InstagramSocialIcon /> : '' }
          </Box>
        </div>
      </Box>
    </Container>
  )
}

export default ThankYou;
