import MultiStepper from "components/Forms/MultiStep-Estimate/MultiStepper";
import SingleStepper from "components/Forms/SingleStep-Estimate/SingleStepper";
import ServiceStepper from "components/Forms/Service-Support/GeneralForm/ServiceStepper";
import ServiceIssuesStepper from "components/Forms/Service-Support/ServiceIssuesForm/ServiceIssuesStepper";
import ModalForm from "components/Forms/Modal-Form/ModalForm";
import ZipChecker from "components/Forms/ZipChecker-Estimate/ZipChecker";
import Careers from "components/Forms/Careers/Careers";
import CallCenter from "components/Forms/Careers/CallCenter";
import IReferred from "components/Forms/Referral/IReferred/IReferred";
import ReferAFriend from "components/Forms/Referral/ReferAFriend/ReferAFriend";
import Referral from "components/Forms/Referral/Referral";
import WarrantyForm from "components/Forms/WarrantyForm/WarranyForm";
import LHSSMultiStepEstimate from "components/Forms/LHSS/LHSS-MultiStep-Estimate/LHSSMultiStepEstimate";
import SignUp from "components/Forms/SignUp";
import SingleStepperLHWS from "../components/Forms/LHWS/SingleStep-Estimate/SingleStepperLHWS";
import LHWSMultiStepEstimate from "../components/Forms/LHWS/LHWSMultiStepEstimate/LHWSMultiStepEstimate";
import SelfScheduler from "components/Forms/Self-Scheduler/SelfScheduler";
import ComponentSelect from "components/ComponentSelect";
import ShortLeadForm from "components/Forms/ShortLead-Form/ShortLeadForm";
import LeafHomeZipChecker from "components/Forms/Leafhome/ZipChecker-Estimate/ZipChecker";
import LeafHomeMultiStepper from "components/Forms/Leafhome/MultiStep-Estimate/MultiStepper";
import LeafHomeSingleStepper from "components/Forms/Leafhome/SingleStep-Estimate/SingleStepper";
import LeafHomeLHSSMultiStepper from "components/Forms/Leafhome/LHSS-MultiStep-Estimate/LHSSMultiStepEstimate";
import LeafHomeLHWSMultiStepper from "components/Forms/Leafhome/LHWS/LHWSMultiStepEstimate/LHWSMultiStepEstimate";
import LeafHomeLHWSSingleStepper from "components/Forms/Leafhome/LHWS/SingleStep-Estimate/SingleStepperLHWS";
import LeafHomeSelfScheduler from "components/Forms/Leafhome/Self-Scheduler/SelfScheduler";
import LeafHomeShortLeadForm from "components/Forms/Leafhome/ShortLead-Form/ShortLeadForm";

import Privacy from "components/Forms/PrivacyForm/Privacy";

// Mapper for mapping identifier and form.
// Key is identifier while value is actual component.
export const mappedComponents = {
  MultiStepper: MultiStepper,
  SingleStepper: SingleStepper,
  ServiceStepper: ServiceStepper,
  ServiceIssuesStepper: ServiceIssuesStepper,
  ModalForm: ModalForm,
  ZipChecker: ZipChecker,
  Careers: Careers,
  IReferred: IReferred,
  ReferAFriend: ReferAFriend,
  Referral: Referral,
  CallCenter: CallCenter,
  WarrantyForm: WarrantyForm,
  LHSSMultiStepEstimate: LHSSMultiStepEstimate,
  SignUp: SignUp,
  SingleStepperLHWS: SingleStepperLHWS,
  LHWSMultiStepEstimate: LHWSMultiStepEstimate,
  SelfScheduler: SelfScheduler,
  ComponentSelect: ComponentSelect,
  ShortLeadForm: ShortLeadForm,
  LeafHomeZipChecker: LeafHomeZipChecker,
  LeafHomeMultiStepper: LeafHomeMultiStepper,
  LeafHomeSingleStepper: LeafHomeSingleStepper,
  LeafHomeLHSSMultiStepper: LeafHomeLHSSMultiStepper,
  LeafHomeLHWSMultiStepper: LeafHomeLHWSMultiStepper,
  LeafHomeLHWSSingleStepper: LeafHomeLHWSSingleStepper,
  LeafHomeSelfScheduler: LeafHomeSelfScheduler,
  LeafHomeShortLeadForm: LeafHomeShortLeadForm,
  PrivacyForm: Privacy
};

export default function componentMap(id) {
  return mappedComponents[id] || null;
}
