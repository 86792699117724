import { ErrorMessage, Field } from "formik";
import LeafBox from 'components/atoms/LeafBox';

const CareerLocation = () => {
  const inputStyle = {
    width: '100%',
    borderRadius: '3px',
    height: '40px',
    border: '1px solid #d1d1d1',
    padding: '5px 10px',
  };
  return (
    <LeafBox>
      <label htmlFor="location">
        Location
      </label>
      <Field
        as="select"
        name="location"
        id="location"
        style={inputStyle}
      >
        <option value="No Response" defaultValue="" disabled="">Choose</option>
        <option value="Hudson">Hudson, OH</option>
        <option value="Las Vegas">Las Vegas, NV</option>
      </Field>
      <ErrorMessage
        name="location"
        component="p"
        className="error-msgs"
      />
    </LeafBox>
  )
}

export default CareerLocation;
