import React, { Children } from "react";
import { Box } from "@mui/material";

const LeafBox = ({style, children, ...props}) => {
  return (
    <Box marginBottom={2} sx={style} {...props}>
      {Children.map(children, child => <>{child}</>)}
    </Box>
  )
}

export default LeafBox;
