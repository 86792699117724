import React, { createContext, useEffect, useState } from "react";
import InitialStep from './InitialStep';
import ReferralDetailsStep from './ReferralDetailsStep';
import GetJobDetails from './GetJobDetails';
import ReferRegistrationStep from './ReferRegistrationStep';
import ThankYou from './thankyou';
import ReactRecaptcha3 from 'react-google-recaptcha3';

export const IReferredContext = createContext();

const IReferred = (props) => {
  const [activeIRStep, setActiveIRStep] = useState('');
  const [formData, setFormData] = useState();
  const [recaptchaScore, setRecaptchaScore] = useState();

  useEffect(() => {
    ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_SITE_KEY).then(status => {
    })
  }, []);

  let stepContent;
  switch (activeIRStep) {
    case 'referral':
      stepContent = <ReferralDetailsStep {...props} />;
      break;
    case 'jobdetails':
      stepContent = <GetJobDetails {...props} />;
      break;
    case 'registration':
      stepContent = <ReferRegistrationStep {...props} />;
      break;
    case 'thankyou':
      stepContent = <ThankYou {...props} />;
      break;
    default:
      stepContent = <InitialStep {...props} />;
    break;
  }

  return (
    <IReferredContext.Provider
      value={{ activeIRStep, setActiveIRStep, formData, setFormData, recaptchaScore, setRecaptchaScore }}>
      <div className="leaffilter-forms i-referred-form">{stepContent}</div>
    </IReferredContext.Provider>
  )
}

export default IReferred;
