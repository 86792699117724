import React from "react";
import { Alert, Stack } from "@mui/material";

const AlertMessage = ({ message, severity }) => {
  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity={severity}>{message}</Alert>
      <br />
    </Stack>
  );
}

export default AlertMessage;
