import React from "react";
import { Box, Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";

import { BlogSocialIcon, FacebookSocialIcon, InstagramSocialIcon, TwiiterSocialIcon } from "components/atoms/SocialMediaIcon";
import { useProduct } from "hooks";


// Import SVG Icons.

const ThankYou = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const product = useProduct();

  return (
     <Container  maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <h2 className="section-header pt-3"> Thank you for your interest in LeafFilter </h2>
          <div className="header-description centered"> You are all set for your 10% off coupon. We will reach out shortly to schedule your free inspection and estimate. </div>

          <br/>
          <h4 className="header-description centered"> Stay Engaged with the LeafFilter Community </h4>

          <br/>
          <Box sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                paddingTop: '16px',
              }}
              >
              <FacebookSocialIcon />
              { product === 'us' || product === 'ca' ? <TwiiterSocialIcon /> : ''}
              <BlogSocialIcon />
              { product === 'lhws' ? <InstagramSocialIcon /> : '' }
              </Box>
      </Container>
  );
}

export default ThankYou;
