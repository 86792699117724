import { useReducer, useContext } from "react";
import { mappedComponents } from "../../utility/utility";
import { FormContext } from "App";
import { Container } from "@mui/material";
import LeafBox from 'components/atoms/LeafBox';

const initialState = {
  selectedComponent: "",
  brand: "",
  country: "",
  subReactComponent: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setSelectedComponent":
      return { ...state, selectedComponent: action.payload};
    case "setBrand":
      return { ...state, brand: action.payload };
    case "setCountry":
      return { ...state, country: action.payload };
    case "setSubReactComponent":
      return { ...state, subReactComponent: action.payload };
    default:
      return state;
  }
};

const ComponentSelect = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setBrand, setCountry } = useContext(FormContext);

  let Component = "";
  if (state.selectedComponent) {
    Component = mappedComponents[state.selectedComponent];
  }


  const handleChange = (event) => {
    event.preventDefault();
    dispatch({ type: "setSelectedComponent", payload: event.target.value });
  };

  const handleBrandChange = (event) => {
    setBrand(event.target.value)
    dispatch({ type: "setBrand", payload: event.target.value });
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value)
    dispatch({ type: "setCountry", payload: event.target.value });
  };

  const handlesubReactComponentChange = (event) => {
    dispatch({ type: "setSubReactComponent", payload: event.target.value });
  };

  return (
    <Container maxWidth={"sm"}>
      <LeafBox>
        <label htmlFor="selectedComponent">Select a component:</label>
        <select
          id="selectedComponent"
          name="selectedComponent"
          value={state.selectedComponent}
          onChange={handleChange}
          required
        >
          <option value="">Select a component</option>
          {Object.keys(mappedComponents).map((option) => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </select>
      </LeafBox>
      <LeafBox>
        <label htmlFor="brand">Select a brand:</label>
        <select id="brand" name="brand" value={state.brand} onChange={handleBrandChange}>
          <option value="">Select a brand</option>
          <option>lhss</option>
          <option>lhws</option>
        </select>
      </LeafBox>
      <LeafBox>
        <label htmlFor="country">Select a country:</label>
        <select id="country" name="country" value={state.country} onChange={handleCountryChange}>
          <option value="">Select a country</option>
          <option>us</option>
          <option>ca</option>
        </select>
      </LeafBox>
      <LeafBox>
        <label htmlFor="subReactComponent">Select a sub-React component:</label>
        <select
          id="subReactComponent"
          name="subReactComponent"
          value={state.subReactComponent}
          onChange={handlesubReactComponentChange}
          disabled={!state.selectedComponent}
        >
          <option value="">Select a sub-React component</option>
          {Object.keys(mappedComponents).map((option) => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </select>
      </LeafBox>
      {
        Component && (<div id={state.selectedComponent}><Component data-brand={state.brand} data-country={state.country} modalComponent={state.subReactComponent} zipCheckerComponent={state.subReactComponent} component={state.selectedComponent}></Component></div>)
      }
    </Container>
  );
};

export default ComponentSelect;
