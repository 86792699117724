import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useRef, useState } from "react";
import * as yup from "yup";
import { Checkbox, Container, FormControlLabel, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import postservice from "utility/postService";
import Validation from "utility/validation";
import TextInput from "components/atoms/TextInput";
import PhoneNumberInput from "components/atoms/PhoneNumberInput";
import EmailInput from "components/atoms/EmailInput";
import ZipCodeInput from "components/atoms/ZipcodeInput";
import { LeafButton } from "components/atoms/LeafButtons";
import { useReCaptchaScore } from "hooks";
import ReCAPTCHA from "react-google-recaptcha"
import TextArea from "components/atoms/TextArea";
import { FormContextPrivacy } from "./Privacy";
import './privacy.scss'

const PrivacyForm = (props) => {
  const { setactiveStepIndex } =
    useContext(FormContextPrivacy);
  let errorMsg = '';
  let captchaScore = null;

  // Disabling recaptcha.
  const reCaptchaShow = process.env.PANTHEON_ENVIRONMENT === 'live' ? (process.env.REACT_APP_RECAPTCHA_SHOW_PROD === 'enable') : (process.env.REACT_APP_RECAPTCHA_SHOW_NON_PROD === 'enable');

  const renderError = (message) => <p className="error-msgs">{message}</p>;
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);

  const ValidationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Phone Number is not valid.")
      .required("Phone Number is required."),
    firstName: yup.string().required("First Name is required."),
    address: yup.string().required("Address is required."),
    city: yup.string().required("City is required."),
    lastName: yup.string().required("Last Name is required."),
    emailAddress: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email Address is not valid.')
      .required("Email Address is required."),
    zipCode: yup.number()
      .typeError('Zip code must be a number')
      .required('Zip code is required')
      .positive('Zip code must be positive')
      .integer('Zip code must be an integer'),
    description: yup.string().required("Description is required"),
    state: yup.string().required("State/Province is required"),
    declareInformation: yup
      .boolean()
      .oneOf([true], "You must declare that the information is correct."),
    rights: yup.array()
      .min(1, "At least one right must be checked.")
      .required("Rights is required")
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const recaptchaRef = useRef();

  const reCaptchaScoreConstant = useReCaptchaScore();
  const handleCheckboxChange = (name) => {
    setSelectedCheckbox(selectedCheckbox === name ? null : name);
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        address: "",
        zipCode:  "",
        state: "",
        city: "",
        description: "",
        makingRequestOnBehalf: false,
        makingRequestAsAuthorizedAgent: false,
        rights: [],
        declareInformation: false

      }}
      validationSchema={ValidationSchema}
      onSubmit={async (values, actions) => {

        let captchaValue = '';
        if (reCaptchaShow) {
          // Validate if the user is not a Robot.
          const RecaptchaVerification = await Validation.RecaptchaVerification();
          captchaScore = RecaptchaVerification.score;

          if (captchaScore < reCaptchaScoreConstant) {
            captchaValue = recaptchaRef.current.getValue();
          }
          else {
            captchaValue = RecaptchaVerification.token;
          }
       }

        if ((captchaValue || !reCaptchaShow)) {

          let submittedValue = {}

          try {
            let response = {}

            submittedValue = {
              "firstName": values.firstName,
              "lastName": values.lastName,
              "emailAddress": values.emailAddress,
              "phoneNumber": values.phoneNumber,
              "address": values.address,
              "city": values.city,
              "state": values.state,
              "zipCode": values.zipCode,
              "makingRequestOnBehalf": values.makingRequestOnBehalf,
              "makingRequestAsAuthorizedAgent": values.makingRequestAsAuthorizedAgent,
              "rights": {
                  "confirm": values.rights.includes('confirm') ? true : false,
                  "access": values.rights.includes('access') ? true : false,
                  "deletion": values.rights.includes('deletion') ? true : false,
                  "correction": values.rights.includes('correction') ? true : false,
                  "withdrawConsent": values.rights.includes('withdrawConsent') ? true : false,
                  "optOutAdvertising": values.rights.includes('optOutAdvertising') ? true : false,
                  "optOutSale": values.rights.includes('optOutAdvertising') ? true : false,
                  "optOutProfiling": values.rights.includes('optOutProfiling') ? true : false,
                  "appeal": values.rights.includes('appeal') ? true : false,
              },
              "description": values.description,
              "declareInformation": values.declareInformation,
              "isInternal": false,
              'recaptcha_token': captchaValue,
              'recaptcha_score': captchaScore,
            }
            response = await postservice.postData('privacy-form', submittedValue, false);
            if (response.success === true) {
              setactiveStepIndex("thankyou");
            }
            else {
              errorMsg = "Sorry, there was an error during submission. Please try again.";
              actions.setSubmitting(false);
            }
          } catch (error) {
            console.log(error);
            console.log("Error in sending data");
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting, touched, errors }) => (
        <div className="privacy-form">
          <Container maxWidth={isMobile ? "xs" : "sm"}>
            <Form
              onSubmit={handleSubmit}
            >
              <h2 className="heading">Private Data Request</h2>
              {errorMsg ? (<div className="border border-danger border-1 p-2 mb-4 error-msg fw-normal">{errorMsg}</div>) : ""}
              <TextInput fieldName={'firstName'} label={'First Name'} placeholder={"Enter Your First Name"} />
              <TextInput fieldName={'lastName'} label={'Last Name'} placeholder={"Enter Your Last Name"} />
              <EmailInput fieldName={'emailAddress'} placeholder={"Please Enter Your Email Address"}/>
              <PhoneNumberInput fieldName={'phoneNumber'} />
              <TextInput fieldName={'address'} label={'Street Address'} placeholder={'Street Address'} />
              <TextInput fieldName={'city'} label={'City'} placeholder={'Enter Your City'} />
              <TextInput fieldName={'state'} label={'State/Province'} placeholder={'Enter Your State/Province'} />
              <ZipCodeInput fieldName={'zipCode'} placeholder={"Enter Your ZipCode"} />
              <TextArea className="text-editor" fieldName={"description"} label={"Description of Request"} placeholder={"Enter Description of Request"} required={true} />
              <div className="field-container">
                <Field name="makingRequestOnBehalf">
                  {({ field }) => (
                    <FormControlLabel
                    checked={selectedCheckbox === 'makingRequestOnBehalf'}
                    onChange={() => {
                      handleCheckboxChange('makingRequestOnBehalf');
                      field.onChange({ target: { name: field.name, value: 'true' } });
                    }}
                      control={<Checkbox name="makingRequestOnBehalf" />}
                      label="Making Request on My Own Behalf"
                      value="true"
                    />
                  )}

                </Field>
                <ErrorMessage name="makingRequestOnBehalf" render={renderError} />
              </div>
              <div className="field-container">
                  <Field name="authroizedagent">
                    {({ field }) => (
                      <FormControlLabel
                        checked={selectedCheckbox === 'authroizedagent'}
                        onChange={() => {
                          handleCheckboxChange('authroizedagent');
                          field.onChange({ target: { name: field.name, value: 'true' } });
                        }}
                        control={<Checkbox name="authroizedagent" />}
                        label="Making Request on Another's Behalf As Authorized Agent"
                        value="true"
                      />
                    )}

                  </Field>
                  <ErrorMessage name="authroizedagent" render={renderError} />
              </div>
              <div className="field-container rights-container">
                <label>Rights </label>
                    <Field name="rights" required={true}  className={`input-field ${touched.rights && errors.rights
                        ? "error"
                        : touched.rights
                          ? "correct"
                          : ""
                      }`}>
                    {({ field }) => (
                      <>
                      <div className="rights-option-label" style={{marginLeft: '2rem'}}>
                        <FormControlLabel

                          onChange={field.onChange}
                          control={<Checkbox name="rights" />}
                          label="Right to Confirm"
                          value="confirm"
                        />
                      </div>
                      <div className="rights-option-label" style={{marginLeft: '2rem'}}>
                        <FormControlLabel
                          onChange={field.onChange}
                          control={<Checkbox name="rights" />}
                          label="Right to Request Access (Data Portability)"
                          value="access"
                        />
                      </div>
                      <div className="rights-option-label" style={{marginLeft: '2rem'}}>
                        <FormControlLabel
                          onChange={field.onChange}
                          control={<Checkbox name="rights" />}
                          label="Right to Request Deletion"
                          value="deletion"
                        />
                      </div>
                      <div className="rights-option-label" style={{marginLeft: '2rem'}}>
                        <FormControlLabel
                          onChange={field.onChange}
                          control={<Checkbox name="rights" />}
                          label="Right to Request Correction"
                          value="correction"
                        />
                      </div>
                      <div className="rights-option-label" style={{marginLeft: '2rem'}}>
                        <FormControlLabel
                          onChange={field.onChange}
                          control={<Checkbox name="rights" />}
                          label="Right to Withdraw Consent (Where consent was the basis for collection or processing)"
                          value="withdrawConsent"
                        />
                      </div>
                      <div className="rights-option-label" style={{marginLeft: '2rem'}}>
                        <FormControlLabel
                            onChange={field.onChange}
                            control={<Checkbox name="rights" />}
                            label="Right to Opt-Out of Targeted Advertising"
                            value="optOutAdvertising"
                        />
                      </div>
                      <div className="rights-option-label" style={{marginLeft: '2rem'}}>
                        <FormControlLabel
                          onChange={field.onChange}
                          control={<Checkbox name="rights" />}
                          label="Right to Opt-Out of Sale"
                          value="optOutSale"
                        />
                      </div>
                      <div className="rights-option-label" style={{marginLeft: '2rem'}}>
                        <FormControlLabel
                          onChange={field.onChange}
                          control={<Checkbox name="rights" />}
                          label="Right to Opt-Out of Automated Profiling in Furtherance of Legally Significant or Similar Decisions"
                          value="optOutProfiling"
                        />
                      </div>
                      <div className="rights-option-label" style={{marginLeft: '2rem'}}>
                        <FormControlLabel
                          onChange={field.onChange}
                          control={<Checkbox name="rights" />}
                          label="Right to Appeal"
                          value="appeal"
                        />
                      </div>
                      </>
                    )}
                  </Field>
                  <ErrorMessage name="rights" render={renderError} />
              </div>
              <div className="field-container">
                <Field name="declareInformation">
                  {({ field }) => (
                    <FormControlLabel
                      onChange={field.onChange}
                      control={<Checkbox name="declareInformation" />}
                      label="I Declare that the information given by me is correct to the best of my knowledge, and that I am entitled to make the request identified above under the laws applicable to me"
                      value="true"
                    />
                  )}
                </Field>
                <ErrorMessage name="declareInformation" render={renderError} />
              </div>
              {reCaptchaShow && (
                <div className="recaptcha">
                  {captchaScore !== null && captchaScore < reCaptchaScoreConstant && (
                    <>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="normal"
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
                      />
                      <p className="error-msgs">reCAPTCHA verification failed. Please try again.</p>
                    </>
                  )}
                </div>
              )}

              <LeafButton buttonType={"tertiary"} type="submit" name="submit" isSubmitting={isSubmitting}>
                {isSubmitting ? ("Submitting") : ("Rights Request Submission ")}
              </LeafButton>
            </Form>
          </Container>
        </div>
      )}
    </Formik>
  );
}

export default PrivacyForm;
