import React, { createContext, useState } from "react";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import "./style.scss";
import LeafBox from 'components/atoms/LeafBox';
import ReferAFriend from './ReferAFriend/ReferAFriend';
import IReferred from './IReferred/IReferred';
export const FormContextReferral = createContext();

const Referral = () => {
  const [refer, setReferral] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let IReferredComponent = <IReferred />;
  let ReferAFriendComponent = <ReferAFriend />;
  if (refer === 'IReferred') {
    ReferAFriendComponent = '';
  } else if (refer === 'ReferAFriend') {
    IReferredComponent = '';
  }

  return (
    <FormContextReferral.Provider
      value={{ refer, setReferral }}
    >
      <Container disableGutters maxWidth={isMobile ? "xs" : "lg"}>
        <LeafBox>
          <h2 className="text-center pt-5 fw-normal"> Refer a Friend </h2>
        </LeafBox>
        <div className={`leaffilter-forms referral-form ${ refer }`}>
          { ReferAFriendComponent }
          { IReferredComponent }
        </div>
      </Container>
    </FormContextReferral.Provider>
  )
}

export default Referral;
