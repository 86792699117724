import React from "react";
import { Container, Paper, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import AlertMessage from "components/atoms/AlertMessage";

const ThankYou = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)} ${isMobile ? theme.spacing(2) : theme.spacing(6)}`,
    borderRadius: 8,
    width:"100%",
    height: (isMobile ? "auto" : 580),
  }));

  return (
    <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
      <StyledFormContainer className="thankyou-wrap fw-normal fs-1">
        <AlertMessage message={'Your information has been received!'} severity={'success'} />
        <div>
        Thank you for your submission. A member of the data privacy team will contact you about your request if there are any questions.
        <br />

        Personal privacy is important to our team.
      </div>
      </StyledFormContainer>
    </Container>
  );
}

export default ThankYou;
