import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { IReferredContext } from "./IReferred";
import * as yup from "yup";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import TermAndCondition from "components/atoms/TermAndCondition";
import Image from './referral/sample-job.jpg';
import JobNumberInput from "components/atoms/JobNumber";
import { LeafButton } from "components/atoms/LeafButtons";

const GetJobDetails = (props) => {
  const { setActiveIRStep, formData, setFormData } =
  useContext(IReferredContext);
  const SampleJobImage = Image;

  const ValidationSchema = yup.object().shape({
    jobNumber: yup
      .string()
      .matches('^[a-zA-Z]{3}\\d+$', "Job Number is not valid.")
      .required("Please match the requested format."),
  });

  // Redirect to next step if I dont know button is clicked.
  const SkipThisStep = (event) => {
    if(event.target.id === "skip-step") {
      setActiveIRStep('registration');
    }
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Formik
      initialValues={{
        jobNumber: "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        setActiveIRStep('registration');
      }
    }
    >
      {({ handleSubmit, isSubmitting }) => (
        <Container  maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <Form
            className="flex flex-col justify-center items-start referral__container--fluid"
            onSubmit={handleSubmit}
          >
            <h1 className="section-header h2"> Your Friend's Job Number </h1>

            <JobNumberInput fieldName={'jobNumber'} label={'Job Number'} />

            <div className="buttons d-flex justify-content-center align-items-center mb-3">
              <span>
                <LeafButton buttonType={"primary"} type="submit" isSubmitting={isSubmitting}>
                  Next
                </LeafButton>
              </span>

              <span class="ms-3">
                <LeafButton buttonType={"secondary"} onClick={SkipThisStep} id={'skip-step'} type="submit" isSubmitting={isSubmitting}>
                  I Dont Know
                </LeafButton>
              </span>
            </div>
            <p className="text-slate-600 text-xs">Tip: Their Job Number is located on the documentation left when the product was installed. An example of the kind of number you are looking for is:</p>
            <img className="profile-photo" src={SampleJobImage} alt={"Graphic exemplifying a sample job number"}/>
            <br/><br/>
            <TermAndCondition />
          </Form>
        </Container>
      )}
    </Formik>
  );
}

export default GetJobDetails;
