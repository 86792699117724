import React, { useContext, createContext } from "react";
import { FormContext } from "../../../App";
import Step1 from './Step1';
import Step2 from '../MultiStep-Estimate/Step4';
import TrustedFormScript from "components/atoms/TrustedFormScript";
import ThankYou from '../Self-Scheduler/ThankYou';
import ThankYouB from '../Self-Scheduler/ThankYouB';
import ThankYouC from '../Self-Scheduler/ThankYouC';
export const FormContextShortLead = createContext();

const ShortLeadForm = (props) => {
  const { activeStepIndex } = useContext(FormContext);
  const thankYouSection = props.dataSet?.thankyoumessage ? props.dataSet.thankyoumessage : '';

  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <Step1 {...props} />;
      break;

    case 1:
      stepContent = <Step2 {...props} />;
      break;

    case 2:
      switch (thankYouSection) {
        case 'test-B':
          stepContent = <ThankYouB {...props} />;
          break;

        case 'test-C':
          stepContent = <ThankYouC {...props} />;
          break;

        default:
          stepContent = <ThankYou {...props} />;
        break;
      }
      break;

    default:
      break;
  }

  return (
    <FormContextShortLead.Provider value={{}}>
      <div className="leaffilter-forms">{stepContent}</div>
      <TrustedFormScript />
    </FormContextShortLead.Provider>
  );
}

export default ShortLeadForm;
