import { useVersion } from 'hooks';

export const LeafButton = ({ style = {}, buttonType, children, handleClick, buttonClass, noTransform=false, isSubmitting=false, ...props }) => {

  let btnClass = '';
  const defaultClass = 'd-flex mx-auto justify-content-center';
  const v2 = useVersion();

  switch (buttonType) {
    case 'primary':
      btnClass = 'btn-primary';
      break;
    case 'secondary':
      btnClass = 'btn-secondary';
      break;
    case 'tertiary':
      btnClass = 'btn-tertiary py-2';
      break;
    case 'secondary-primary':
      btnClass= 'btn-secondary-primary py-2';
      break;
    default:
      btnClass = 'btn-primary';
      break;
  }

  if (noTransform) {
    btnClass += " no-transition";
  }

  btnClass += " " + defaultClass;

  if (buttonClass) {
    btnClass = buttonClass;
  }

  return (

    <button
      className={`btn ${btnClass} ${v2 ? 'w-100' : ''}`}
      style={ style }
      onClick={ handleClick }
      disabled={isSubmitting ? "disabled": ""}
      {...props}
      >
      {children}
    </button>
  )
}
