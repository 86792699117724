import React, { useContext } from "react";
import { FormContext } from "../../../App";
import CareersForm from './CareersForm';
import ThankYouMessageCareer from './ThankYouMessageCareer';

const Careers = (props) => {
  const { activeStepIndex } = useContext(FormContext);
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <CareersForm {...props} />;
      break;
    case 1:
      stepContent = <ThankYouMessageCareer {...props} />;
      break;
    default:
      break;
  }

  return <div className="leaffilter-forms career-form">{stepContent}</div>;
}

export default Careers;
