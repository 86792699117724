import React, { useContext } from "react";
import { FormContext } from "../../../../App";
import InitialStep from './InitialStep';
import VerifyInstallation from './VerifyInstallation';
import ReferRegistration from "./ReferRegistration";
import ThankYou from "./thankyou";

const ReferAFriend = (props) => {
  const { activeStepIndex } = useContext(FormContext);
  let stepContent;
  switch (activeStepIndex) {
    case 'verify':
      stepContent = <VerifyInstallation {...props} />;
      break;
    case 'registration':
      stepContent = <ReferRegistration {...props} />;
      break;
    case 'thankyou':
        stepContent = <ThankYou {...props} />;
        break;
    default:
      stepContent = <InitialStep {...props} />;
      break;
  }

  return <div className="leaffilter-forms refer-a-friend">{stepContent}</div>;
}

export default ReferAFriend;
