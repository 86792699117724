import React, { createContext, useState } from "react";
import RegisterWarranty from './RegisterWarranty';
import WarrantyNotFound from "./WarrantyNotFound";
import WarrantyAlreadyExists from "./WarrantyAlreadyExists";
import GetWarrantyDetails from "./GetWarrantyDetails";
import ThankYou from "./ThankYou";
import '../LHSS/global-brand.scss';
export const WarrantyFormContext = createContext();

const WarrantyForm = (props) => {
  const [activeStep, setActiveStep] = useState();
  const [formData, setFormData] = useState();

  let stepContent;
  switch (activeStep) {
    case 'RegisterWarranty':
      // @todo RegisterWarranty needs to be handled in GetWarrantyDetails.js.
      stepContent = <RegisterWarranty {...props} />;
      break;

    case 'WarrantyDoesNotExist':
      stepContent = <WarrantyNotFound {...props} />;
      break;

    case 'WarrantyAlreadyExists':
      stepContent = <WarrantyAlreadyExists {...props} />;
      break;

    case 'ThankYou':
      stepContent = <ThankYou {...props} />;
      break;

    default:
      stepContent = <GetWarrantyDetails {...props} />;
      break;
  }

  return (
    <WarrantyFormContext.Provider
      value={{ activeStep, setActiveStep, formData, setFormData }}>
      <div className="leaffilter-forms warranty-form">{stepContent}</div>
    </WarrantyFormContext.Provider>
  )
}

export default WarrantyForm;
