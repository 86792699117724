import React from "react";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useGetPhoneNumber } from "utility/countryOverride";
import AlertMessage from "components/atoms/AlertMessage";

const ThankYouForm = () => {
  const phoneNumber = useGetPhoneNumber();
  const telPhoneNumber = phoneNumber.replace('-', '');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className="service-support-form">
      <Container maxWidth={isMobile ? "xs" : "sm"}>
        <AlertMessage message={'Thanks for contacting LeafFilter™. Your Service & Support submission has been received successfully. If your comments require a response we will be in touch shortly, typically within 1-2 business days.'} severity={'success'} />
        <div className="thankyou-wrap">
          <p>
            If you have any immediate needs or concerns please contact us at
            <br />
            <span>
              <a href={`tel:+${telPhoneNumber}`} className="phone-number">{`${phoneNumber}`}</a>
            </span>
          </p>
        </div>
      </Container>
    </div>
  );
}

export default ThankYouForm;
