import React from "react";
import { Helmet } from 'react-helmet';
import { getactiveTestScriptAP } from '../../../utility/functions';

const TrustedFormScript = () => {
  const activeProspectTestScript = getactiveTestScriptAP();
  let sandbox = '';
  if (activeProspectTestScript === 'sandbox') {
    sandbox = '&sandbox=true';
  }

  return (
    <>
      <Helmet>
        {/* Insert your script tag in the head */}
        <script type="text/javascript">
        {`
          (function() {
            var tf = document.createElement('script');
            tf.type = 'text/javascript'; tf.async = true;
            tf.src = ("https:" == document.location.protocol ? 'https' : 'http') + "://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl` + sandbox + `&l=" + new Date().getTime() + Math.random();
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tf, s);
          })();
        `}
        </script>
      </Helmet>
    </>
  );
}

export default TrustedFormScript;
