import { useSiteURL } from "utility/countryOverride";

const TermAndConditionLHWS = ({buttonText}) => {
  let siteUrl = useSiteURL();
  const privacyURL =  'https://privacy.leafhome.com/';

  return (
    <p className="text-slate-600 text-xs mt-3 form-disclaimer">By clicking {buttonText}, I consent to receive marketing prerecorded and automated calls from Leaf Home Water Solutions and other LHWS Subsidiaries at the phone number provided and agree to the {" "}
    <a href={`${siteUrl}/terms-of-use/`} target="_blank" rel="noreferrer"><u>Terms of use</u></a> and <a href={privacyURL} target="_blank" rel="noreferrer"><u>Privacy Policy</u></a>. Consent is not a condition of purchase.</p>
  );
}

export default TermAndConditionLHWS;
