import { Field, ErrorMessage } from "formik";
import LeafBox from 'components/atoms/LeafBox';

const GeneralQuestionSubjects = () => {
  const inputStyle = {
    width: '100%',
    borderRadius: '3px',
    height: '40px',
    border: '1px solid #d1d1d1',
    padding: '5px 10px',
  };
  return (
    <LeafBox>
      <label htmlFor="subject">Subject</label>
      <Field
        component="select"
        name="subject"
        id="subject"
        style={inputStyle}
      >
        <option value="General Questions/Comments">General Questions/Comments</option>
        <option value="Estimate Request">Estimate Request</option>
        <option value="Feedback/Testimonial">Feedback/Testimonial</option>
        <option value="Other">Other</option>
      </Field>
      <ErrorMessage
        name="selectedOption"
        component="p"
        className="error-msgs"
      />
    </LeafBox>
  )
}

export default GeneralQuestionSubjects;
