import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { FormContext } from "../../../../App";
import * as yup from "yup";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import "./style.scss";
import postservice from "utility/postService";
import DataLayerService from "utility/dataLayerService";
import Validation, { useZipCodeValidation } from "utility/validation";
import TermOfServiceComponent from "components/atoms/TermOfService";
import sanitize from "sanitize-html";
import TextInput from "components/atoms/TextInput";
import PhoneNumberInput from "components/atoms/PhoneNumberInput";
import EmailInput from "components/atoms/EmailInput";
import ZipCodeInput from "components/atoms/ZipcodeInput";
import { LeafButton } from "components/atoms/LeafButtons";
import JobNumberInput from "components/atoms/JobNumber";
import { useCountry, useBrand, useReCaptchaScore } from "hooks";
import ReCAPTCHA from "react-google-recaptcha"

const ServiceIssuesForm = (props) => {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);
  const country = useCountry();
  let errorMsg = '';
  let captchaScore = null;
  const sanitizeOptions = {
    allowedTags: [],
    allowedAttributes: {},
  };
  let uploadFile = {};
  let cloudinaryFilePath = '';
  let fileUploadError = '';
  let fileError = false;
  const fileValidateType = [
    "image/jpeg",
    "image/jpg",
    "image/gif",
    "image/png",
    "application/pdf",
  ];

  // Disabling recaptcha.
  const reCaptchaShow = process.env.PANTHEON_ENVIRONMENT === 'live' ? (process.env.REACT_APP_RECAPTCHA_SHOW_PROD === 'enable') : (process.env.REACT_APP_RECAPTCHA_SHOW_NON_PROD === 'enable');

  // Zipcode Validation based on the Country Code.
  const zipCodeValidation = useZipCodeValidation();
  const brand = useBrand();
  const renderError = (message) => <p className="error-msgs">{message}</p>;

  const ValidationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Phone Number is not valid.")
      .required("Phone Number is required."),
    firstName: yup.string().required("First Name is required."),
    address: yup.string().required("Address is required."),
    city: yup.string().required("City is required."),
    selectedOptionState: yup.string().required("State is required."),
    lastName: yup.string().required("Last Name is required."),
    emailAddress: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email Address is not valid.')
      .required("Email Address is required."),
    zipCode: yup
      .string()
      .matches(zipCodeValidation.validationPattern, zipCodeValidation.validationMessage)
      .required(`${zipCodeValidation.zipCodeText} is required.`),
  });

  const handleOnFocus = (event) => {
    // Passing lf.form.start event to DataLayer.
    DataLayerService.formStartEvent('standard', 'support-form');

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent('standard', 'support-form');
  };

  const handleSelectFile = (event) => {
    const file = event.target.files[0];
    fileError = false;

    // checking if upload size if greater than 10 mb
    if (event.target.files[0].size > 10 * 1000 * 1024) {
      fileUploadError = "File with maximum size of 10 MB is allowed."
      fileError = true;
    }

    if (fileValidateType.indexOf(file.type) === -1) {
      fileUploadError = "File extension is not allowed."
      fileError = true;
    }

    if (!fileError) {
      uploadFile = event.target.files[0];
    }
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const recaptchaRef = useRef();

  const reCaptchaScoreConstant = useReCaptchaScore();
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        description: "",
        address: "",
        zipCode: localStorage.getItem("zipCode") || "",
        city: "",
        selectedOption: "",
        selectedOptionState: "",
        termsOfService: false,
        jobNumber: ''
      }}
      validationSchema={ValidationSchema}
      onSubmit={async (values, actions) => {
        const data = { ...formData, ...values };

        // Creating form Data for upload file
        if (uploadFile) {
          const uploadFileData = new FormData();
          uploadFileData.append("cloudinary_file", uploadFile);
          uploadFileData.append("product", brand);
          const fileResponse = await postservice.uploadFile('upload', uploadFileData);

          if (fileResponse.secure_url) {
            cloudinaryFilePath = fileResponse.secure_url;
          }
        }

        let captchaValue = '';
        if (reCaptchaShow) {
          // Validate if the user is not a Robot.
          const RecaptchaVerification = await Validation.RecaptchaVerification();
          captchaScore = RecaptchaVerification.score;

          if (captchaScore < reCaptchaScoreConstant) {
            captchaValue = recaptchaRef.current.getValue();
          }
          else {
            captchaValue = RecaptchaVerification.token;
          }
       }

        if ((captchaValue || !reCaptchaShow) && !fileError) {

          let submittedValue = {}

          const subjectValue = values.selectedOption ? values.selectedOption : 'Service Request';

          try {
            let response = {}
            if (brand === 'lhss' || brand === 'lhws') {
              let htmlText = '<p>New Form is submitted:</p>';
              htmlText = '<ul>';
              htmlText += '<li>First Name: ' + sanitize(values.firstName, sanitizeOptions) + '</li>';
              htmlText += '<li>Last Name: ' + sanitize(values.lastName, sanitizeOptions) + '</li>';
              htmlText += '<li>Email Address: ' + sanitize(values.emailAddress, sanitizeOptions) + '</li>';
              htmlText += '<li>Phone Number: ' + sanitize(values.phoneNumber, sanitizeOptions) + '</li>';
              htmlText += '<li>Subject: ' + sanitize(subjectValue, sanitizeOptions) + '</li>';
              htmlText += '<li>Address: ' + sanitize(values.address, sanitizeOptions) + '</li>';
              htmlText += '<li>City: ' + sanitize(values.city, sanitizeOptions) + '</li>';
              htmlText += '<li>State: ' + sanitize(values.selectedOptionState, sanitizeOptions) + '</li>';
              htmlText += '<li>Job Number: ' + sanitize(values.jobNumber, sanitizeOptions) + '</li>';
              htmlText += '<li>Zip Code: ' + sanitize(values.zipCode, sanitizeOptions) + '</li>';

              if (cloudinaryFilePath) {
                htmlText += '<li>Cloudinary File Path: ' + cloudinaryFilePath + '</li>';
              }

              htmlText += '<li>Comments: ' + sanitize(values.description, sanitizeOptions) + '</li>';
              htmlText += '</ul>';

              submittedValue = {
                html: htmlText,
                subject: "Service  - " + subjectValue,
                brand: brand
              }
              response = await postservice.sendMail('send-mail', submittedValue);

              if (response.success === false) {
                errorMsg = "Sorry, we couldn't send mail. Please contact site administrator for details.";
                actions.setSubmitting(false);
              }
            }
            else {

              submittedValue = {
                'web_lead_source': "",
                'Web Lead Source': "",
                'country': country,
                'emailAddress': values.emailAddress,
                'firstName': values.firstName,
                'lastName': values.lastName,
                'phoneNumber': values.phoneNumber,
                'zipCode': values.zipCode,
                'address': values.address,
                'guid': '8afbc85c-25dc-4310-a111-4c2ce2ba315a',
                'city': values.city,
                'state': values.selectedOptionState,
                'comments': values.description,
                'subject': subjectValue,
                'landing_page': window.location.pathname,
                'jobNumber': values.jobNumber,
                'source': 41,
                'recaptcha_token': captchaValue,
                'recaptcha_score': captchaScore,
              }
              response = await postservice.postData('service-issues', submittedValue, true);

              if (response.success === false) {
                errorMsg = "Sorry, there was an error during submission. Please try again.";
                actions.setSubmitting(false);
              }
            }
            if (response.success === true) {
              setFormData(data);

              // Passing lf.form.success event to DataLayer.
              DataLayerService.formSuccessEvent('standard', 'contact', 'support-form');

              setActiveStepIndex(activeStepIndex + 1);
            }
            else {
              errorMsg = "Sorry, there was an error during submission. Please try again.";
              actions.setSubmitting(false);
            }
          } catch (error) {
            console.log(error);
            console.log("Error in sending data");
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting, touched, errors }) => (
        <div className="service-support-form">
          <Container maxWidth={isMobile ? "xs" : "sm"}>
            <Form
              onSubmit={handleSubmit}
              onFocus={
                (event) => { handleOnFocus(event) }
              }
            >
              <h3 className="heading">Send Us a Message!</h3>
              {errorMsg ? (<div className="border border-danger border-1 p-2 mb-4 error-msg fw-normal">{errorMsg}</div>) : ""}
              <TextInput fieldName={'firstName'} label={'First Name'} placeholder={"E.g., John"} />
              <TextInput fieldName={'lastName'} label={'Last Name'} placeholder={"E.g., Smith"} />
              <PhoneNumberInput fieldName={'phoneNumber'} />
              <EmailInput fieldName={'emailAddress'} />
              <TextInput fieldName={'address'} label={'Address'} placeholder={'Address'} />
              <TextInput fieldName={'city'} label={'City'} placeholder={'City'} />

              <div className="field-container">

                { ( brand === 'lhss' || brand === 'lhws' ) ?
                  <>
                    <label htmlFor="selectedOptionState">State</label>
                    <Field
                      as="select"
                      name="selectedOptionState"
                      id="selectedOptionState"
                      className="input-field"
                    >
                      <option value="SelectState" defaultValue="" disabled="">
                        Select state
                      </option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District of Columbia">District of Columbia</option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option
                      ><option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </Field>
                  </>
                  :
                  country === 'us' ? (
                    <>
                      <label htmlFor="selectedOptionState">State</label>
                      <Field
                        as="select"
                        name="selectedOptionState"
                        id="selectedOptionState"
                        className="input-field"
                      >
                        <option value="SelectState" defaultValue="" disabled="">
                          Select State
                        </option>
                        <option value="AL">AL</option>
                        <option value="AK">AK</option>
                        <option value="AZ">AZ</option>
                        <option value="AR">AR</option>
                        <option value="CA">CA</option>
                        <option value="AL">AL</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DE">DE</option>
                        <option value="DC">DC</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="HI">HI</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="IA">IA</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="ME">ME</option>
                        <option value="MD">MD</option>
                        <option value="MA">MA</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MS">MS</option>
                        <option value="MO">MO</option>
                        <option value="MT">MT</option>
                        <option value="NE">NE</option>
                        <option value="NV">NV</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NY">NY</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VT">VT</option>
                        <option value="VA">VA</option>
                        <option value="WA">WA</option>
                        <option value="WV">WV</option>
                        <option value="WI">WI</option>
                        <option value="WY">WY</option>
                      </Field>
                    </>
                  )
                    :
                    <>
                      <label htmlFor="selectedOptionState">Province</label>
                      <Field
                        as="select"
                        name="selectedOptionState"
                        id="selectedOptionState"
                        className="input-field"
                      >
                        <option value="SelectState" defaultValue="" disabled="">
                          Select Province
                        </option>
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="NU">Nunavut</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="YT">Yukon</option>
                      </Field>
                    </>
                }

                <ErrorMessage
                  name="selectedOptionState"
                  component="p"
                  className="error-msgs"
                />
              </div>

              <ZipCodeInput fieldName={'zipCode'} />
              <div className="field-container">
                <JobNumberInput fieldName={'jobNumber'} label={'Job Number'} />
              </div>

              <div className="field-container">
                <label htmlFor="selectedOption">
                  Subject
                </label>
                <Field
                  as="select"
                  name="selectedOption"
                  id="selectedOption"
                  className={`input-field ${touched.selectedOption && errors.selectedOption
                      ? "error"
                      : touched.selectedOption
                        ? "correct"
                        : ""
                    }`}
                >
                  {( brand === 'lhss' || brand === 'lhws' ) ?
                    <>
                      <option value="" defaultValue="" disabled="">
                        Select Subject
                      </option>
                      <option value="Service Request">
                        Service Request
                      </option>
                      <option value="Warranty Request">Warranty Request</option>
                      <option value="Other">Other</option>
                    </>
                    :
                    <>
                      <option value="" defaultValue="" disabled="">
                        Select Subject
                      </option>
                      <option value="Service Request">
                        Service Request
                      </option>
                      <option value="Warranty Request">Warranty Request</option>
                      <option value="Other">Other</option>
                    </>
                  }
                </Field>
                <ErrorMessage name="selectedOption" render={renderError} />
              </div>

              {( brand === 'lhss' || brand === 'lhws' ) ?
                <div className="field-container">
                  <label htmlFor="uploadFile">
                    File
                  </label>
                  <input className="input-field " type="file" name="uploadFile" multiple={false} onChange={handleSelectFile} />
                  <p>Accepted file types: jpg, jpeg, gif, png, pdf, Max. file size: 10 MB.</p>
                  {fileUploadError ? <p className="error-msgs"> {fileUploadError} </p> : ''}
                </div>
                : ''}
              <div className="field-container">
                <div style={{
                  minWidth: "150px"
                }}>
                  <label>Description</label>
                  <span className="optional">(optional)</span>
                </div>
                <Field
                  as="textarea"
                  name="description"
                  className="form-control-textarea"
                  placeholder={"Description"}
                />
              </div>
              {country === 'ca' ? (
                <TermOfServiceComponent />
              ) : ''}

              {reCaptchaShow && (
                <div className="recaptcha">
                  {captchaScore !== null && captchaScore < reCaptchaScoreConstant && (
                    <>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="normal"
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
                      />
                      <p className="error-msgs">reCAPTCHA verification failed. Please try again.</p>
                    </>
                  )}
                </div>
              )}

              {brand === 'lhss' ?
                <LeafButton buttonType={"primary"} type="submit" name="submit" isSubmitting={isSubmitting}>
                  {isSubmitting ? ("Submitting") : ("Send Email")}
                </LeafButton>
                : ''
              }

              { brand === 'lhws' ?
                <LeafButton buttonType={"secondary"} type="submit" name="submit" isSubmitting={isSubmitting}>
                {isSubmitting ? ("Submitting") :  'Submit'}
                </LeafButton>
                : ''
              }

              { (brand !== 'lhss' && brand !== 'lhws' ) ?
               <LeafButton buttonType={"tertiary"} type="submit" name="submit" isSubmitting={isSubmitting}>
                  {isSubmitting ? ("Submitting") : ("Send Email")}
                </LeafButton>
                : ''
              }


                {brand === 'lhss' ? <div className="message-wrap"><p>
                  Want to get in touch via email? Send us a message at
                  <a href="mailto:support@leafhomesafetysolutions.com">support@leafhomesafetysolutions.com</a>
                  with your phone number, zip code, and a description of your experience.
                </p>
                </div> : ''}

              {brand === 'lhws' ?
              <div className="message-wrap"><p>
                Want to get in touch via email? Send us a message at
                <a href="mailto:support@leafhomewatersolutions.com ">support@leafhomewatersolutions.com </a>
                with your phone number, zip code, and a description of your experience.
              </p>
              </div> : ''}
            </Form>
          </Container>
        </div>
      )}
    </Formik>
  );
}

export default ServiceIssuesForm;
