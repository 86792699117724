import React from "react";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import AlertMessage from "components/atoms/AlertMessage";

const ThankYouMessageCallCenter = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className="service-support-form">
      <Container maxWidth={isMobile ? "xs" : "sm"}>
        <AlertMessage message={'Thank you for your submission. Someone will be in touch with you shortly.'} severity={'success'} />
      </Container>
    </div>
  );
}

export default ThankYouMessageCallCenter;
