import { ErrorMessage, Field } from "formik";
import React from "react";
import ToolTipComponent from "components/atoms/Tooltip";
import validation from "utility/validation";
import LeafBox from "components/atoms/LeafBox";

const TermOfServiceComponent = () => {
  const errorStyle = {
    color: '#c90000',
    fontWeight: '600',
    marginBottom: '0',
  };
  const renderError = (message) => <p className="error-msg" style={errorStyle}>{message}</p>;

  return (
    <LeafBox>
      <Field validate={validation.ValidateTermsofService} type="checkbox" name="termsOfService" id="termsOfService"></Field>
      <label htmlFor="termsOfService" className="ms-1 position-cursor">
          I agree to be contacted.
          <ToolTipComponent/>
      </label>
      <ErrorMessage name="termsOfService" render={renderError} />
    </LeafBox>
  )
}

export default TermOfServiceComponent;
