import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { WarrantyFormContext } from "./WarranyForm";
import { useGetPhoneNumber } from "utility/countryOverride";

import { ReactComponent as PhoneIcon } from "components/Forms/form-icons/phone-icon.svg"
import { LeafButton } from 'components/atoms/LeafButtons';
import LeafBox from "components/atoms/LeafBox";
import { useBrand } from "hooks";

const WarrantyNotFound = (props) => {
  const brand = useBrand();
  const phoneNumber = useGetPhoneNumber();
  let notFoundText = '';

  const { setActiveStep } = useContext(
    WarrantyFormContext
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  notFoundText = "Sorry, We couldn't find your " + (brand === 'lhss' || brand === 'lhws' ? 'project' : 'Install') + " records.";

  return (
    <Formik
      initialValues={{

      }}
      onSubmit={() => {
        setActiveStep('GetWarrantyDetails');
      }
      }
    >
      {({ handleSubmit}) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <Form onSubmit={handleSubmit} className="mx-auto text-center w-75">
            <h1 className="fw-normal mb-4 h2">{notFoundText}</h1>
            <h2 className="h4 mb-4">Please verify your job number and try again</h2>
            <LeafBox>
              {brand === 'lhws' ?
              <LeafButton buttonType={"secondary"} type="submit">Try Again</LeafButton>
              :
              <LeafButton buttonType={"tertiary"} type="submit">Try Again</LeafButton>
              }
            </LeafBox>
            <LeafBox>
              <h3>Or, call us for further assistance</h3>
            </LeafBox>
            <LeafBox>
              <div className="h4 fw-normal">
                <PhoneIcon className="phone-icon"></PhoneIcon>
                {`${phoneNumber}`}
              </div>
            </LeafBox>
          </Form>
        </Container>
      )}
    </Formik>
  );
}

export default WarrantyNotFound;
