import { Field, ErrorMessage } from "formik";
import LeafBox from 'components/atoms/LeafBox';

const WaterTypeSelect = () => {
  const inputStyle = {
    width: '100%',
    borderRadius: '3px',
    height: '40px',
    border: '1px solid #d1d1d1',
    padding: '5px 10px',
  };
  return (
    <LeafBox>
      <label htmlFor="waterType">
        Water Type{" "}
      </label>
      <Field
        component="select"
        name="waterType"
        id="waterType"
        style={inputStyle}
      >
        <option defaultValue="none">- Select -</option>
        <option value="City Water">City Water</option>
        <option value="Well Water">Well Water</option>
        <option value="Unsure">Unsure</option>
      </Field>
      <ErrorMessage
        name="selectedOption"
        component="p"
        className="error-msgs"
      />
    </LeafBox>
  )
}

export default WaterTypeSelect;
