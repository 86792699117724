import { Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { IReferredContext } from "./IReferred";
import * as yup from "yup";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import PostService from "utility/postService";
import TermAndCondition from "components/atoms/TermAndCondition";
import EmailInput from "components/atoms/EmailInput";
import PhoneNumberInput from "components/atoms/PhoneNumberInput";
import ZipCodeInput from "components/atoms/ZipcodeInput";
import TextInput from "components/atoms/TextInput";
import { LeafButton } from "components/atoms/LeafButtons";
import validation from "utility/validation";
import ReCAPTCHA from "react-google-recaptcha"
import { useReCaptchaScore } from "hooks";

const ReferRegistrationStep = (props) => {
  const { setActiveIRStep, formData } =
    useContext(IReferredContext);

  const reCaptchaScoreConstant = useReCaptchaScore();
  let errorMsg = "";
  let captchaScore = null;

  // Disabling recaptcha.
  const reCaptchaShow = process.env.PANTHEON_ENVIRONMENT === 'live' ? (process.env.REACT_APP_RECAPTCHA_SHOW_PROD === 'enable') : (process.env.REACT_APP_RECAPTCHA_SHOW_NON_PROD === 'enable');

  const ValidationSchema = yup.object().shape({
    referralName: yup
      .string()
      .trim()
      .matches(/[^\s\d]+ .+$/, "Referral Name must include both First Name and Last Name.")
      .required('Referral Name is required.'),
    referralEmail: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Referral Email is not valid.')
      .required("Referral Email is required."),
    referralPhone: yup.string().required("Phone is required."),
    referralZip: yup.string().required("Zip is required."),
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const recaptchaRef = useRef();

  return (
    <Formik
      initialValues={{
        referralName: "",
        referralEmail: "",
        referralPhone: "",
        referralZip: "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={async (values, actions) => {
        const data = { ...formData, ...values };
        const [firstName, lastName] = data.name.trim().split(' ');
        const [referralFirstName, referralLastName] = data.referralName.trim().split(' ');
        let captchaValue = '';

        if (reCaptchaShow) {
          const RecaptchaVerification = await validation.RecaptchaVerification();

          captchaScore = RecaptchaVerification.score;

          if (captchaScore < process.env.REACT_APP_RECAPTCHA_SCORE) {
            captchaValue = recaptchaRef.current.getValue();
          }
          else {
            captchaValue = RecaptchaVerification.token;
          }
       }

        if (captchaValue || !reCaptchaShow) {

          try {
            const args = {
              FirstName: firstName,
              LastName: lastName,
              Email: data.email,
              Phone: data.phone,
              Zip: data.zip,
              ReferrerFirstName: referralFirstName,
              ReferrerLastName: referralLastName,
              ReferrerEmail: data.referralEmail,
              ReferrerJobNumber: data.jobNumber,
              Notes: "",
              recaptcha_token: captchaValue,
              recaptcha_score: captchaScore,
            };
            const loadData = await PostService.postData('save-lead-referral', args);
            if (loadData.success) {
              setActiveIRStep('thankyou');
            }
            else {
              errorMsg = "Sorry, there was an error during submission. Please try again.";
              actions.setSubmitting(false);
            }
          } catch (error) {
            console.log(error);
            console.log("Error in sending data");
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <Form
            className="flex flex-col justify-center items-start referral__container--fluid"
            onSubmit={handleSubmit}
          >
            <h1 className="section-header h2"> How can we contact you? </h1>
            <div className="header-description"> Please enter your contact details to receive your 10% off coupon and we'll contact you shortly to set up your free inspection & estimate. </div>
            <br />

            {errorMsg ? (<div className="border border-danger border-1 p-2 mb-4 error-msg fw-normal">{errorMsg}</div>) : ""}

            <TextInput fieldName={'referralName'} label={'Full Name'} placeholder={"E.g., John Smith"} />
            <EmailInput fieldName={'referralEmail'} />
            <PhoneNumberInput fieldName={'referralPhone'} />
            <ZipCodeInput fieldName={'referralZip'} label={'Postal / Zip Code'} country={''} />

            {reCaptchaShow && (
              <div className="recaptcha">
                {captchaScore !== null && captchaScore < reCaptchaScoreConstant && (
                  <>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="normal"
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
                    />
                    <p className="error-msgs">reCAPTCHA verification failed. Please try again.</p>
                  </>
                )}
              </div>
            )}
            <LeafButton buttonType={"primary"} type="submit" isSubmitting={isSubmitting}>{isSubmitting ? 'Submitting' : 'Finish Registration'}</LeafButton>
            <TermAndCondition />
          </Form>
        </Container>
      )}
    </Formik>
  );
}

export default ReferRegistrationStep;
