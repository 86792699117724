import { useProduct, useVersionLeafhome } from "hooks";

/**
 * Get the main site URL based on product.
 *
 * @returns {string}
 */
export const useSiteURL = () => {
  let url = "https://www.leaffilter.com";
  switch (useProduct()) {
    case 'ca':
      url = "https://www.leaffilter.ca";
      break;
    case 'lhss':
      url = "https://www.leafhomesafetysolutions.com";
      break;
    case 'lhws':
      url = "https://www.leafhomewatersolutions.com"
      break;
    default:
      url = "https://www.leaffilter.com";
      break;
  }

  if (useVersionLeafhome()) {
    // Override to keep it as relative url.
    url = "";
  }

  return url;
}

/**
 * Get the phone number based on country.
 *
 * @returns {string}
 */
export const useGetPhoneNumber = () => {
  let number = '1-800-290-6106';
  switch (useProduct()) {
    case 'ca':
      number = "1-844-211-4281"
      break;
    case 'lhss':
      number = "1-833-376-8129";
      break;
    case 'lhws':
      number = '1-888-701-5497';
      break;
    default:
      number = '1-800-290-6106';
      break;
  }
  return number;
}

/**
 * Get the DNC phone number based on country.
 *
 * @returns {string}
 */
export const useDNCPhoneNumber = () => {
  let number = '+1 877-308-0811';
  switch (useProduct()) {
    case 'lhss':
      number = '+1 877-308-0817';
      break;

    case 'lhws':
      number = '+1 877-308-0938';
      break;

    default:
      number = '+1 877-308-0811';
      break;
  }
  return number;
}

/**
 * Get the Facebook URL
 *
 * @returns {string}
 */
export const useFacebookURL = () => {
  let url = 'https://www.facebook.com/LeafFilter/';
  switch (useProduct()) {
    case 'ca':
      url = "https://www.facebook.com/LeafFilterCanada/"
      break;
    case 'lhss':
      url = "https://www.facebook.com/leafhomesafety/";
      break;
    case 'lhws':
      url = 'https://www.facebook.com/LeafHomeWater/';
      break;
    default:
      url = 'https://www.facebook.com/LeafFilter/';
      break;
  }
  return url;
}

/**
 * Get twitter url
 *
 * @returns {string}
 */
export const useTwitterURL = () => {
  let url = 'https://twitter.com/leaffilter/';
  switch (useProduct()) {
    case 'ca':
      url = "https://twitter.com/leaffilter_Ca"
      break;
    case 'lhss':
      url = "";
      break;
    case 'lhws':
      url = '';
      break;
    default:
      url = 'https://twitter.com/leaffilter/';
      break;
  }
  return url;
}

/**
 * Get Blog Url
 *
 * @returns {string}
 */
export const useBlogURL = () => {
  let url = 'https://www.leaffilter.com/blog/';
  switch (useProduct()) {
    case 'ca':
      url = "https://www.leaffilter.ca/blog/"
      break;
    case 'lhss':
      url = "https://www.leafhomesafetysolutions.com/blog/";
      break;
    case 'lhws':
      url = 'https://www.leafhomewatersolutions.com/blog/';
      break;
    default:
      url = 'https://www.leaffilter.com/blog/';
      break;
  }

  if (useVersionLeafhome()) {
    url = "/blog/";
  }
  return url;
}

/**
 * get Insta url
 *
 * @returns {string}
 */
export const useInstaURL = () => {
  let url = '';
  switch (useProduct()) {
    case 'lhws':
      url = 'https://www.instagram.com/leafhomewater/';
      break;
    default:
      url = '';
      break;
  }
  return url;
}


/**
 * Get Site name.
 *
 * @returns {string}
 */
export const useSiteName = () => {
  let siteName = '';
  const vLeafhome = useVersionLeafhome();
  switch (useProduct()) {
    case 'lhss':
      siteName = `${vLeafhome ? 'Leaf Home Safety Solutions, LLC' : 'Leaf Home Safety Solutions'}`;
      break;

    case 'lhws':
      siteName = `${vLeafhome ? 'Leaf Home Water Solutions, LLC' : 'Leaf Home Water Solutions'}`;
      break;

    default:
      siteName = `${vLeafhome ? 'LeafFilter North, LLC' : 'LeafFilter'}`;
      break;
  }

  return siteName;
}

/**
 * Get Site name for Terms and condition section.
 *
 * @returns {string}
 */
export const useSiteNameforTC = () => {
  let siteNameTC = '';
  const vLeafhome = useVersionLeafhome();
  switch (useProduct()) {
    case 'lhss':
      siteNameTC = `${vLeafhome ? 'Leaf Home Safety Solutions, LLC' : 'Leaf Home Safety Solutions and other LHSS Subsidiaries'}`;
      break;

    case 'lhws':
      siteNameTC = `${vLeafhome ? 'Leaf Home Water Solutions, LLC' : 'Leaf Home Water Solutions and other LHWS Subsidiaries'}`;
      break;

    default:
      siteNameTC = `${vLeafhome ? 'LeafFilter North, LLC' : 'LeafFilter'}`;
      break;
  }
  return siteNameTC;
}
