import { useSiteURL, useSiteName } from "utility/countryOverride";
import { useProduct, useVersionLeafhome } from "hooks";

const TermAndCondition = () => {
  let siteUrl = useSiteURL();
  const vLeafhome = useVersionLeafhome();
  const siteName = useSiteName();
  if (useProduct() === 'ca') {
    siteUrl = 'https://www.leafhome.com';
  }
  const privacyURL =  'https://privacy.leafhome.com/';

  return (
    <p className="text-slate-600 text-xs mt-3 form-disclaimer">By submitting this form, I agree to the {`${vLeafhome ? siteName : 'Leaf Home'}`}{" "}
    <a href={`${siteUrl}/terms-of-use/`} target="_blank" rel="noreferrer"><u>Terms of use</u></a> and <a href={privacyURL} target="_blank" rel="noreferrer"><u>Privacy Policy</u></a>, as well as to receive SMS and calls about my projects.</p>
  );
}

export default TermAndCondition;
