import React from "react";
import { Container, Paper, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import TermAndCondition from "components/atoms/TermAndCondition";
import { useProduct, useComponentName } from "hooks";
import DataLayerService from "utility/dataLayerService";

const ThankYou = (props) => {
  const product = useProduct();
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)} ${isMobile ? theme.spacing(2) : theme.spacing(6)}`,
    borderRadius: 8,
    width:"100%",
  }));

  if (userJourneyTracking) {
    // Update the StepNumber for ShortLeadform.
    let stepNumber = 4;
    if ((componentName === 'ShortLeadForm') || (props.modalComponent === 'ShortLeadForm')) {
      stepNumber = 2;
      if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
        stepNumber = 3;
      }
    }
    else {
      if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
        stepNumber = 5;
      }
    }

    // Number of required Fields.
    let requiredFields = 4;
    if (product === 'ca') {
      requiredFields = 5;
    }

    // Passing lf.form.status event to DataLayer.
    DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, requiredFields, 'completed');
  }

  return (
    <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
      <StyledFormContainer className="thankyou-wrap fw-normal fs-1 thankyou-B">
        <div className="step5-main-text">
          Schedule your <br /> FREE consultation to get your <br /> NO OBLIGATION estimate now.
        </div>
        <br />

        <Divider className="divider" />
        <TermAndCondition />
      </StyledFormContainer>
    </Container>
  );
}

export default ThankYou;
