import { Field, useFormikContext } from 'formik';
import React, { useContext } from "react";
import LeafBox from 'components/atoms/LeafBox';
import InputMask from "react-input-mask";
import ValidationIcon from "components/atoms/ValidationIcon";
import { FormContext } from 'App';

const JobNumberInput = ({fieldName, label }) => {
  const { errors, touched } = useFormikContext();
  const { brand } = useContext(FormContext);

  let placeholderText = 'E.g, AKR12345';
  let maskFormat = '[a-zA-Z]';
  let maskText = 'aaa99999';

  if (brand === 'lhss') {
    placeholderText = 'E.g, HAKR12345';
    maskText = 'aaaa99999';
  }
  else if ( brand === 'lhws') {
    placeholderText = 'E.g, WORL12345';
    maskText = 'aaaa99999';
  }

  let inputStyle = {
    width: '100%',
    borderRadius: '3px',
    height: '40px',
    border: '1px solid #d1d1d1',
    padding: '5px 10px',
  };

  return (
    <LeafBox display="block">
      { label ? (<label>{label}</label>) : '' }
      <Field name={fieldName}>
        {({ field }) => (
            <InputMask
              {...field}
              className={`input-field jobnumber-input ${
                touched[fieldName] && errors[fieldName]
                  ? "error"
                  : touched[fieldName]
                  ? "correct"
                  : ""
              }`}
              mask={ maskText }
              maskChar=""
              formatChars= {{
                'a': maskFormat,
                '9': '[0-9]',
              }}
              placeholder={ placeholderText }
              style={inputStyle}
            />
            )}
      </Field>
      <ValidationIcon fieldName={fieldName} touched={touched} errors={errors} />
    </LeafBox>
  );
}

export default JobNumberInput;
